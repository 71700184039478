// CITY

export const SET_PREFFERED_CITY = 'SET_PREFFERED_CITY';

// AUTH

export const LOGIN_USER = 'LOGIN_USER';
export const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS';
export const SET_LOGGEDIN_ADDRESS = 'SET_LOGGEDIN_ADDRESS';
export const CHANGE_ACCESS_TOKEN = 'CHANGE_ACCESS_TOKEN';
export const USER_CART_COUNT = 'USER_CART_COUNT';
export const SET_USER_CART = 'SET_USER_CART';
export const SET_USER_CAR_DETAILS = 'SET_USER_CAR_DETAILS';
export const SET_USER_BIKE_DETAILS = 'SET_USER_BIKE_DETAILS';
export const SET_MARKETING_HEADER_TEXT = 'SET_MARKETING_HEADER_TEXT';
export const SET_DEVICE_TYPE = 'SET_DEVICE_TYPE';
export const SET_ACTIVE_SERVICE_TYPE = 'SET_ACTIVE_SERVICE_TYPE';
export const SET_ACTIVE_BRAND = 'SET_ACTIVE_BRAND';
export const SET_REVIEWS_CURRENT_CITY = 'SET_REVIEWS_CURRENT_CITY';
export const SET_REGIONS_OF_CITY = 'SET_REGIONS_OF_CITY';
export const SET_SELECTED_REGION = 'SET_SELECTED_REGION';
export const SET_FAQS_FOR_CITY = 'SET_FAQS_FOR_CITY';
export const SET_BLOGS_LIST = 'SET_BLOGS_LIST';
export const SET_USER_LOGGED_IN = 'SET_USER_LOGGED_IN';
export const SET_ORDER_DATE_TIME = 'SET_ORDER_DATE_TIME';
export const SET_APPLIED_COUPON = 'SET_APPLIED_COUPON';
export const SET_USER_SELECTED_CAR_ID = 'SET_USER_SELECTED_CAR_ID';
export const SET_CAR_FOR_CART = 'SET_CAR_FOR_CART';
export const SET_CART_RESOLVED = 'SET_CART_RESOLVED';
export const SET_USER_ACCESSORIES_CART = 'SET_USER_ACCESSORIES_CART';
export const SET_SEGMENTS = 'SET_SEGMENTS';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_ACTIVE_SEGMENT = 'SET_ACTIVE_SEGMENT';
export const SET_ACTIVE_CATEGORY = 'SET_ACTIVE_CATEGORY';
export const SET_LEAD = 'SET_LEAD';
export const SET_STORES_FOR_CITY = 'SET_STORES_FOR_CITY';
export const SET_SELECTED_STORE = 'SET_SELECTED_STORE';
export const SET_NEAR_BY_STORE = 'SET_NEAR_BY_STORE';
export const SET_STORE_LOCATION = 'SET_STORE_LOCATION';
export const SET_ACCESSORIES_CAR_DETAILS = 'SET_ACCESSORIES_CAR_DETAILS';
export const SET_ACCESSORIES_CAR_BRAND = 'SET_ACCESSORIES_CAR_BRAND';
export const SET_ACCESSORIES_VEHICLE_TYPE = 'SET_ACCESSORIES_VEHICLE_TYPE';
export const SET_STAR_PRODUCTS_LIST = 'SET_STAR_PRODUCTS_LIST';
export const SET_HOT_CATEGORIES_LIST = 'SET_HOT_CATEGORIES_LIST';
export const SET_PRODUCT_PANEL_PAGE = 'SET_PRODUCT_PANEL_PAGE';
export const SET_PRODUCT_DETAILS_PAGE = 'SET_PRODUCT_DETAILS_PAGE';
export const SET_SHOW_SERVICES_COLLAPSED = 'SET_SHOW_SERVICES_COLLAPSED';
export const SET_ACCESSORIES_BANNER = 'SET_ACCESSORIES_BANNER';
export const SET_ACCESSORIES_CART_RESOLVED = 'SET_ACCESSORIES_CART_RESOLVED';
export const SET_CLASSIFICATION_PANEL = 'SET_CLASSIFICATION_PANEL';
export const SET_CAR_SELECTION_SHOW = 'SET_CAR_SELECTION_SHOW';
export const SET_ACCESSORIES_FAQ = 'SET_ACCESSORIES_FAQ';
export const SET_ACTIVE_SUBCATEGORY = 'SET_ACTIVE_SUBCATEGORY';
export const SET_DISPLAY_CONFIGURATION = 'SET_DISPLAY_CONFIGURATION';
export const SET_DISPLAY_POSSIBLE_SECTION = 'SET_DISPLAY_POSSIBLE_SECTION';
export const SET_ADD_ON_SERVICES = 'SET_ADD_ON_SERVICES';
export const SET_SEGMENT_DETAILS_PAGE = 'SET_SEGMENT_DETAILS_PAGE';
export const SET_SEGMENT_PAGE_DETAILS = 'SET_SEGMENT_PAGE_DETAILS';
export const SET_CATEGORY_PAGE_DETAILS = 'SET_CATEGORY_PAGE_DETAILS';
export const SET_ACC_HOME_PAGE_DETAILS = 'SET_ACC_HOME_PAGE_DETAILS';
export const SET_SELECTED_ADDR_ID = 'SET_SELECTED_ADDR_ID';
export const SET_TOAST_CONFIG = 'SET_TOAST_CONFIG';
export const SET_SELECTED_PAYMENT_METHOD = 'SET_SELECTED_PAYMENT_METHOD';
export const SET_SELECTED_PAYMENT_METHOD_TYPE = 'SET_SELECTED_PAYMENT_METHOD_TYPE';
export const SET_SELECTED_PAYMENT_METHOD_NAME = 'SET_SELECTED_PAYMENT_METHOD_NAME';
export const SET_REVIEWS_FOR_CATEGORY = 'SET_REVIEWS_FOR_CATEGORY';
export const SET_REVIEWS_FOR_SERVICE = 'SET_REVIEWS_FOR_SERVICE';
export const SET_WARRANTY_CARD = 'SET_WARRANTY_CARD';
export const SET_TIME_SELECT = 'SET_TIME_SELECT';
export const SET_CAR_MODEL_PAGE = 'SET_CAR_MODEL_PAGE';
export const SET_USER_REMARKS = 'SET_USER_REMARKS';
export const SET_ACCESSORIES_COLLECTION_PAGE = 'SET_ACCESSORIES_COLLECTION_PAGE';
export const SET_SERVICE_GUIDE = 'SET_SERVICE_GUIDE';
export const SET_CART_BOOSTER_DATA = 'SET_CART_BOOSTER_DATA';
export const SET_PRICE_LIST_DATA = 'SET_PRICE_LIST_DATA';
export const SET_CAR_BRAND_PAGE = 'SET_CAR_BRAND_PAGE';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const SET_MONSOON_DATA = 'SET_MONSOON_DATA';
export const SET_SERVICE_WARRANTY_DATA = 'SET_SERVICE_WARRANTY_DATA';
export const SET_MILES_DATA = 'SET_MILES_DATA';
export const SET_MILES_PRODUCT_DATA = 'SET_MILES_PRODUCT_DATA';
export const SET_SERVICE_BUDDY_DATA = 'SET_SERVICE_BUDDY_DATA';
export const SET_SEO_SERVICE_DETAILS_DATA = 'SET_SEO_SERVICE_DETAILS_DATA';
export const SET_AMC_USER = 'SET_AMC_USER';
export const SET_ACCESSORIES_CITIES = 'SET_ACCESSORIES_CITIES';
export const SET_FLIPKART_SELECTED_SERVICES = 'SET_FLIPKART_SELECTED_SERVICES';
export const SET_ACC_BRAND_PAGE_DETAILS = 'SET_ACC_BRAND_PAGE_DETAILS';
export const SET_CAR_PAGE_DATA = 'SET_CAR_PAGE_DATA';
export const SET_ALL_BRANDS = 'SET_ALL_BRANDS';
export const SET_ALL_CITIES = 'SET_ALL_CITIES';
export const SET_BRAND_WISE_MODELS = 'SET_BRAND_WISE_MODELS';
export const SET_CATEGORY_LIST = 'SET_CATEGORY_LIST';
export const SET_CITY_WISE_REGIONS = 'SET_CITY_WISE_REGIONS';
export const SET_GOMECHANIC_BENEFITS = 'SET_GOMECHANIC_BENEFITS';
export const SET_LUXURY_BRANDS = 'SET_LUXYRY_BRANDS';
export const SET_OFFERS_PAGE = 'SET_OFFERS_PAGE';
export const SET_POLICY = 'SET_POLICY';
export const SET_POPULAR_BRANDS = 'SET_POPULAR_BRANDS';
export const SET_REVIEW_PAGE_DATA = 'SET_REVIEW_PAGE_DATA';
export const SET_TABLE_DATA = 'SET_TABLE_DATA';
export const SET_WORKSHOP = 'SET_WORKSHOP';
export const SET_SERVICES_DETAILS = 'SET_SERVICES_DETAILS';
export const SET_POPULAR_SERVICE_V2 = 'SET_POPULAR_SERVICE_V2';
export const SET_HOW_GOMECH_WORKS = 'SET_HOW_GOMECH_WORKS';
export const SET_CITY_CAROUSELS = 'SET_CITY_CAROUSELS';
export const SET_WARRANTY_SELECTED_TAB = 'SET_WARRANTY_SELECTED_TAB';
export const SET_WARRANTY_DETAILS_DATA = 'SET_WARRANTY_DETAILS_DATA';
export const SET_WARRANTY_CLAIM_DATA = 'SET_WARRANTY_CLAIM_DATA';
export const SET_OBD_DETAILS_DATA = 'SET_OBD_DETAILS_DATA';
export const SET_ACCESSORIES_COLLECTION_PAGE_V2 = 'SET_ACCESSORIES_COLLECTION_PAGE_V2';
export const SET_ACTIVE_CARS = 'SET_ACTIVE_CARS';
export const SET_ACCESSORIES_ENQUIRY_DATA = 'SET_ACCESSORIES_ENQUIRY_DATA';
export const SET_LAZADA_SELECTED_SERVICES = 'SET_LAZADA_SELECTED_SERVICES';
export const SET_ACC_SEARCH_DATA = 'SET_ACC_SEARCH_DATA';
export const SET_ACC_BLOGS_DATA = 'SET_ACC_BLOGS_DATA';

export const localStorageKeys = {
  AUTH_TOKEN: 'AUTH_TOKEN',
  accessToken: 'accessToken',
  userName: 'userName',
  email: 'email',
  mobile: 'mobile',
  userId: 'userId',
  is_country: 'is_country',
  ref_code: 'ref-code',
  go_app_earning: 'go_app_earning',
  city: 'city',
  city_id: 'city_id'
};

export const HomepageSections = {
  OUR_SERVICES: 'OUR_SERVICES',
  // POPULAR_SERVICES: 'POPULAR_SERVICES',
  GOMECHANIC_CURATED_CUSTOM_SERVICE: 'GOMECHANIC_CURATED_CUSTOM_SERVICE',
  GOMECHANIC_MANSOON_SHIELD: 'GOMECHANIC_MANSOON_SHIELD',
  // WORKSHOP_NEAR_YOU: 'WORKSHOP_NEAR_YOU',
  // GOMECHANIC_LUXE: 'GOMECHANIC_LUXE',
  HOW_GOMECH_WORKS: 'HOW_GOMECH_WORKS',
  // GOMECH_BENEFITS: 'GOMECH_BENEFITS',
  // CUSTOMER_HOME_VIDEO: 'CUSTOMER_HOME_VIDEO',
  RATING_REVIEWS: 'RATING_REVIEWS',
  // OUR_PARTNERS: 'OUR_PARTNERS',
  FREQUENTLY_ASKED_QUESTIONS: 'FREQUENTLY_ASKED_QUESTIONS',
  DOWNLOAD_APP: 'DOWNLOAD_APP',

  // FLEET_OF_CARS: 'FLEET_OF_CARS',
  // LOOKING_FOR_CUSTOM: 'LOOKING_FOR_CUSTOM',
  // BRANDS_WE_SERVICE: 'BRANDS_WE_SERVICE',
  GOMECH_SERVICE_GUIDE: 'GOMECH_SERVICE_GUIDE',
  GOMECH_PRICE_LIST: 'GOMECH_PRICE_LIST',
  // SEE_HOW_ITS_DONE: 'SEE HOW IT DONE',
  // RELATED_BLOGS: 'RELATED_BLOGS'
};

export const cartRules = {
  SAME_SERVICE_TYPE_SINGLE_SELECT_SERVICE: 'SAME_SERVICE_TYPE_SINGLE_SELECT_SERVICE',
  SAME_SERVICE_TYPE_ALL_INCLUSIVE_SERVICE: 'SAME_SERVICE_TYPE_ALL_INCLUSIVE_SERVICE',
};

export const navigationTexts = {};
navigationTexts[HomepageSections.OUR_SERVICES] = 'Our Services';
// navigationTexts[HomepageSections.POPULAR_SERVICES] = 'Popular Services';
navigationTexts[HomepageSections.GOMECHANIC_MANSOON_SHIELD] = 'Summer Services';
navigationTexts[HomepageSections.GOMECHANIC_CURATED_CUSTOM_SERVICE] = 'Curated Custom Service';
navigationTexts[HomepageSections.GOMECHANIC_LUXE] = 'GoMechanic Luxe';
navigationTexts[HomepageSections.HOW_GOMECH_WORKS] = 'How GoMechanic Works?';
navigationTexts[HomepageSections.GOMECH_BENEFITS] = 'GoMechanic Benefits';
navigationTexts[HomepageSections.RATING_REVIEWS] = 'Rating & Reviews';
// navigationTexts[HomepageSections.CUSTOMER_HOME_VIDEO] = 'What customers say about us';
// navigationTexts[HomepageSections.OUR_PARTNERS] = 'Our Partners';
navigationTexts[HomepageSections.FLEET_OF_CARS] = 'Fleet of Cars';
// navigationTexts[HomepageSections.LOOKING_FOR_CUSTOM] = 'Looking for custom ?';
navigationTexts[HomepageSections.BRANDS_WE_SERVICE] = 'Brands We Service';
navigationTexts[HomepageSections.FREQUENTLY_ASKED_QUESTIONS] = 'Frequently asked questions';
navigationTexts[HomepageSections.GOMECH_SERVICE_GUIDE] = 'GoMechanic Service Guide';
navigationTexts[HomepageSections.DOWNLOAD_APP] = 'Download App';
navigationTexts[HomepageSections.GOMECH_PRICE_LIST] = 'Price List';

export const navigationTextsNg = {};
navigationTextsNg[HomepageSections.OUR_SERVICES] = 'Our Services';
navigationTextsNg[HomepageSections.HOW_GOMECH_WORKS] = 'How GoMechanic45 Works?';
navigationTextsNg[HomepageSections.GOMECH_BENEFITS] = 'GoMechanic45 Benefits';
navigationTextsNg[HomepageSections.RATING_REVIEWS] = 'Rating & Reviews';
navigationTextsNg[HomepageSections.OUR_PARTNERS] = 'Our Partners';
navigationTextsNg[HomepageSections.FLEET_OF_CARS] = 'Fleet of Cars';
navigationTextsNg[HomepageSections.LOOKING_FOR_CUSTOM] = 'Looking for custom ?';
navigationTextsNg[HomepageSections.BRANDS_WE_SERVICE] = 'Brands We Service';
navigationTextsNg[HomepageSections.FREQUENTLY_ASKED_QUESTIONS] = 'Frequently asked questions';
navigationTextsNg[HomepageSections.GOMECH_SERVICE_GUIDE] = 'GoMechanic45 Service Guide';
navigationTextsNg[HomepageSections.DOWNLOAD_APP] = 'Download App';
